import { Box, Button, Flex, Heading } from '@everlywell/ui-kit';
import { OptionType } from 'components/MultiSelectAutoComplete';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AllergiesAutoCompleteField } from '../AllergiesAutoCompleteField';

export const MockAllergiesAutoCompleteWrapper = () => {
  const formMethods = useForm({
    defaultValues: { allergiesField: [] },
    mode: 'onBlur',
  });

  const onSubmitHandler = (values: { allergiesField: OptionType[] }) => {
    alert(
      `formData: ${values?.allergiesField?.map(
        (value) =>
          `\nlabel: ${value.label}\nvalue: ${value.value}\n______________________`,
      )}`,
    );
  };

  return (
    <FormProvider {...formMethods}>
      <Flex flexDirection="column" alignItems="center">
        <Heading as="h1">Multi Select AutoComplete Allergies</Heading>
        <Box width={340}>
          <form onSubmit={formMethods.handleSubmit(onSubmitHandler)}>
            <AllergiesAutoCompleteField
              id="allergiesField"
              label="Allergies"
              placeholder="Select an allergy"
              required
              variant={'primary'}
              modules={[]}
              sublabel={null}
              mod_type={'allergies'}
              options_array={[]}
              custom_module_condition={null}
            />
            <Button
              colorScheme="greenCS"
              size="wide"
              type="submit"
              variant="primary"
            >
              Submit
            </Button>
          </form>
        </Box>
      </Flex>
    </FormProvider>
  );
};
