import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
} from '@everlywell/ui-kit';
import React from 'react';

import CarePlanInfoCard, {
  CarePlanInfoCardProps,
} from '../CarePlanInfoCard/CarePlanInfoCard';

export type CarePlanAccordionProps = {
  items: CarePlanInfoCardProps[];
};

const CarePlanAccordion = ({ items }: CarePlanAccordionProps) => {
  const hasSingleItem = (items?.length ?? 0) === 1;
  const hasLessThenThreeItems = (items?.length ?? 0) <= 3;

  const defaultIndex = hasLessThenThreeItems ? [0] : [];

  return (
    <Accordion allowToggle={!hasSingleItem} defaultIndex={defaultIndex}>
      <Flex direction="column" gap={4}>
        {items.map((item, index) => (
          <AccordionItem key={index} border={0}>
            <CarePlanInfoCard
              {...item}
              headerWrapper={{
                component: AccordionButton,
                props: { padding: 0 },
              }}
              bodyWrapper={{
                component: AccordionPanel,
                props: { padding: 0 },
              }}
            />
          </AccordionItem>
        ))}
      </Flex>
    </Accordion>
  );
};

export default CarePlanAccordion;
