import { mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div<{ inMobileWebview: boolean }>`
  padding-top: ${({ inMobileWebview }) => (inMobileWebview ? 0 : '68px')};

  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: ${({ inMobileWebview }) => (inMobileWebview ? 0 : '57px')};
  }
`;

export { Container };
