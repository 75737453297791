import { Box, Icon, Text } from '@everlywell/ui-kit';
import {
  CircleDashed,
  FileText,
  Prescription,
  ArrowSquareUpRight,
  Syringe,
  ExcludeSquare,
  UserFocus,
  Info,
  TextAlignLeft,
} from '@phosphor-icons/react';
import React from 'react';

import { CarePlanInfoCardProps } from '../CarePlanInfoCard';

const IconComponents = {
  CircleDashed: CircleDashed,
  FileText: FileText,
  Prescription: Prescription,
  ArrowSquareUpRight: ArrowSquareUpRight,
  Syringe: Syringe,
  ExcludeSquare: ExcludeSquare,
  UserFocus: UserFocus,
  Info: Info,
  TextAlignLeft: TextAlignLeft,
};

export type CardPlanInfoCardHeaderProps = {
  iconName: CarePlanInfoCardProps['iconName'];
  sectionName: CarePlanInfoCardProps['sectionName'];
  itemsCount: number;
  wrapper: CarePlanInfoCardProps['headerWrapper'];
};

const CarePlanInfoCardHeader = ({
  iconName,
  sectionName,
  itemsCount,
  wrapper,
}: CardPlanInfoCardHeaderProps) => {
  const IconComponent = IconComponents[iconName];

  const hasWrapper = !!wrapper;
  const WrapperComponent = hasWrapper ? wrapper?.component : React.Fragment;
  const wrapperProps = hasWrapper ? wrapper?.props : {};

  return (
    <WrapperComponent {...wrapperProps}>
      <Box
        as="header"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        padding="4"
      >
        <Icon
          as={IconComponent}
          size="md"
          fill="viridian.light"
          weight="regular"
          role="img"
          aria-label={`${iconName} icon`}
        />
        <Text color="tints.darkGrey">{sectionName}</Text>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="28px"
          height="28px"
          borderRadius="full"
          backgroundColor="viridian.wash"
          color="viridian.base"
          visibility={itemsCount > 0 ? 'visible' : 'hidden'}
        >
          {itemsCount > 0 && (
            <Text
              size="md"
              aria-live="polite"
              aria-label={`Items count: ${itemsCount}`}
            >
              {itemsCount}
            </Text>
          )}
        </Box>
      </Box>
    </WrapperComponent>
  );
};

export default CarePlanInfoCardHeader;
