import type { Document } from '@contentful/rich-text-types';
import gql from 'graphql-tag';

const RESULTS_QUERY = gql`
  query GetResults {
    resultCollection {
      items {
        kitTitle
        productSlug
        nonCovidDeliveredText
      }
    }
  }
`;

const PCP_QUESTIONS_QUERY = gql`
  query GetQuestions($productSlug: String!) {
    resultCollection(where: { productSlug: $productSlug }) {
      items {
        productSlug
        pcpQuestionsCollection {
          items {
            questions
          }
        }
      }
    }
  }
`;

export type FactCollectionItem = {
  copy: {
    json: Document;
  };
  icon: {
    title: string;
    description: string;
    contentType: string;
    fileName: string;
    size: number;
    url: string;
    width: number;
    height: number;
  };
  slug: string;
  source1?: string | null;
  source1Url?: string | null;
  source2?: string | null;
  source2Url?: string | null;
  title: string;
};

const FACTS_COLLECTION_QUERY = gql`
  query FactCollectionQuery {
    factCollection {
      items {
        copy {
          json
        }
        icon {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        slug
        source1
        source1Url
        source2
        source2Url
        title
      }
    }
  }
`;

const CARE_PATHWAYS_PAGE_SECTION_QUERY = gql`
  query GetPageCarePathwaysQuery($pageId: String!) {
    pageSectionCarePathways(id: $pageId) {
      sectionId
      slug
      cardsForSectionCollection(limit: 10) {
        items {
          ... on CardsForSection {
            sys {
              id
            }
            articleTitle
            articleUrl
            articleDescription
            articleImage {
              title
              url
            }
          }
          ... on ContentSection {
            sectionId
            title
            headline
            subHeadline
            description
            image {
              title
              url
            }
            slug
            listOfContentSectionsCollection(limit: 10) {
              items {
                ... on ContentSection {
                  sectionId
                  title
                  headline
                  subHeadline
                  description
                  image {
                    title
                    url
                  }
                  modalTitle
                  slug
                }
                ... on CardsForSection {
                  sys {
                    id
                  }
                  articleTitle
                  articleUrl
                  articleDescription
                  articleImage {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
      digDeeperSectionsCollection {
        items {
          ... on CardsForSection {
            sys {
              id
            }
            articleTitle
            articleUrl
            articleDescription
            articleImage {
              title
              url
            }
          }
          ... on ContentSection {
            sectionId
            slug
            title
            description

            listOfContentSectionsCollection(limit: 10) {
              items {
                ... on ContentSection {
                  sectionId
                  title
                  headline
                  subHeadline
                  description
                  image {
                    title
                    url
                  }
                  modalTitle
                  slug
                }

                ... on DigDeeperSection {
                  sys {
                    id
                  }
                  question
                  answer
                }
                ... on CardsForSection {
                  sys {
                    id
                  }
                  articleTitle
                  articleUrl
                  articleDescription
                  articleImage {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ALL_RECOMMENDED_ARTICLES = gql`
  query GetLifestyleArticlesQuery($pageContainerId: String!) {
    pageContainer(id: $pageContainerId) {
      pagesCollection(limit: 6) {
        items {
          title
          attributesCollection(limit: 15) {
            items {
              ... on ProgramAttribute {
                program
                __typename
              }
              ... on ReadTimeAttribute {
                minutes
                __typename
              }
            }
          }
          heroSection {
            ... on HeroSection {
              mobileImage {
                url
              }
            }
          }
          sectionsCollection(limit: 15) {
            items {
              componentName
              headline
              description
              listOfLinksCollection(limit: 15) {
                items {
                  text
                  url
                }
              }
              mobileImagesCollection {
                items {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export {
  FACTS_COLLECTION_QUERY,
  PCP_QUESTIONS_QUERY,
  RESULTS_QUERY,
  CARE_PATHWAYS_PAGE_SECTION_QUERY,
  ALL_RECOMMENDED_ARTICLES,
};
