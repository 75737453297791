import { theme, Flex, FlexProps } from '@everlywell/ui-kit';
import React from 'react';

const CarePlanCardContainer = ({ children, ...rest }: FlexProps) => (
  <Flex
    direction="column"
    backgroundColor="tints.white"
    border={`1px solid ${theme.colors.tints.lightCream}`}
    borderRadius="20px"
    boxShadow={theme.shadows.tooltip}
    {...rest}
  >
    {children}
  </Flex>
);

export default CarePlanCardContainer;
