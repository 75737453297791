import { H1, mediaQueries, size, typography, colors } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const negativeMargin = `-${size.md}px`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;

  ${mediaQueries.forDesktopUp} {
    align-items: stretch;
    margin: 0;
  }
`;

export const PageHeaderStyle = styled(H1)`
  ${typography.h2Text};
  margin-top: 0;
`;

export const StyledBackButtonLink = css({
  color: colors.green4,
  display: 'flex',
  textDecoration: 'none',
  width: '50px', // to allow for more touch surface area
  marginBottom: `${size.sm}px`,
  [mediaQueries.forDesktopUp]: {
    display: 'none',
  },
});

export const InsideContainer = styled.main({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: `${size.lg}px ${size.lg}px ${size.xl7}px`,

  [mediaQueries.forTabletVerticalUp]: {
    padding: `${size.xl2}px ${size.xl2}px ${size.xl7}px`,
  },

  [mediaQueries.forDesktopUp]: {
    padding: `${size.xl3}px ${size.xl3}px ${size.xl7}px`,
  },
});

export const PageHeaderWrapper = styled.div({
  margin: `${negativeMargin} ${negativeMargin} 0`,
  [mediaQueries.forTabletVerticalUp]: {
    margin: `0px`,
  },
});

export const NavWrapper = styled.div`
  display: flex;

  > div {
    align-items: baseline;
    min-height: 100vh;
  }
`;
