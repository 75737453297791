import { TelehealthCarePlanOrderTypes } from 'common/apis/telehealthApis';
import useCarePlan from 'common/hooks/useCarePlan';
import useUser from 'common/hooks/useUser';
import PlanSummary from 'components/PlanSummary';
import CarePlanAccordion from 'components/telehealth/CarePlan/components/CarePlanAccordion/CarePlanAccordion';
import { CarePlanInfoCardProps } from 'components/telehealth/CarePlan/components/CarePlanInfoCard';
import { format } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ORDER_TYPE_MAPPING } from './constants';

export interface AthenaCarePlanPageProps {}

const AthenaCarePlanPage = (props: AthenaCarePlanPageProps) => {
  const { user } = useUser();
  const firstName = user?.first_name;
  const { appointmentId } = useParams();
  const {
    assessment,
    diagnosis,
    providerName,
    orders,
    isLoading: isLoadingCarePlan,
  } = useCarePlan(appointmentId ?? '');

  // placeholder for actual appointment date
  const date = format(new Date(), 'do LLLL yyyy');

  const formattedOrdersAccordionItems = orders
    ? Object.entries(orders).map(([key, value]) => {
        const orderType = key as TelehealthCarePlanOrderTypes;
        return {
          sectionName: ORDER_TYPE_MAPPING?.[orderType]?.text,
          iconName: ORDER_TYPE_MAPPING?.[orderType]?.iconName,
          items: value,
        };
      })
    : [];

  const formattedAccordionItems: CarePlanInfoCardProps[] = [
    {
      sectionName: 'Diagnosis',
      iconName: 'CircleDashed',
      items: diagnosis,
    },
    ...formattedOrdersAccordionItems,
  ];

  // TODO: Add loading state
  // This is a temporary place holder for loading state, design still in progress
  if (isLoadingCarePlan) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h1>Athena Care Plans Page</h1>
      <h2>Appointment id: {appointmentId}</h2>
      <h2>User First Name: {firstName}</h2>
      <br />
      <main style={{ display: 'flex', gap: 16, alignItems: 'flex-start' }}>
        <aside style={{ width: '100%', maxWidth: '450px' }}>
          <CarePlanAccordion items={formattedAccordionItems} />
        </aside>
        <PlanSummary
          date={date}
          assessment={assessment}
          physicianName={providerName}
          programName={'UTI Virtual Care Visit'}
        />
      </main>
    </>
  );
};

export default AthenaCarePlanPage;
